import React from 'react';
import type { NextPage } from 'next';
import Head from 'next/head';

import { NotFound } from 'components/views/NotFound';

const Custom404: NextPage = () => (
  <>
    <Head>
      <title>Hoppsan... Nu blev något fel. - Blipp</title>
    </Head>
    <NotFound />
  </>
);

export default Custom404;
