import React from 'react';
import Image from 'next/image';

import { Layout } from 'components/Layout';

import styles from './NotFound.module.scss';

export const NotFound: React.FC = () => (
  <Layout>
    <div className={styles.container}>
      <Image src="/assets/images/not-found.svg" width={260} height={252} alt="Fel" />
      <h1>Hoppsan... Nu blev något fel.</h1>
      <p>Sidan du letade efter verkar inte finnas.</p>
    </div>
  </Layout>
);
